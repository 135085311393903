/**
 * @file The "viewer" web page's javascript logic to run _very_ early in the page load cycle.
*/
import { ViewerUrl } from './viewer-url.es13.js';
import { redirectViewerIfNecessary, PLATFORM_IS_MOBILE } from "../utils/redirect.es6.js";

console.debug('[page]', 'Initializing ViewerPageInitialization.', Date.now() * .001);

/**
 * @class
 * @classdesc Code required during the earliest phases of page loading. Very few dependencies.
 */
class ViewerPageInitialization {
	constructor() {
		this._initialUrl = new ViewerUrl(window.location.href);
	}

	get initialUrl() {
		return this._initialUrl;
	}
}

window.pageInitialization = new ViewerPageInitialization();

// Redirect to desktop if necessary
redirectViewerIfNecessary(PLATFORM_IS_MOBILE);

export { ViewerPageInitialization }
